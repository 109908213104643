.main-container {
  transition: opacity 500ms linear;
  overflow: hidden !important;
}

.un-mount {
  opacity: 0;
}

.custom-grid-columns {
  max-width: 100vw;
  grid-template-columns: repeat(7, minmax(0, auto));
  overflow: hidden !important;
}

.loader-fade-in {
  animation: fade-in 1.2s linear;
}

.loader-container {
  transform: translate(-50%, -50%);
}

.logo-animate:after {
  content: "";
  top: 0;
  transform: translateX(max(40vw, 375px));
  width: 30px;
  height: 200px;
  position: absolute;
  animation: logo-animate 2.2s linear;
  z-index: 21;
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.8)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    color-stop(100%, rgba(125, 185, 232, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

.logo-fade-out {
  display: none;
}

.grid-container {
  transition: background-color 100ms linear;
  transition: delay 3300ms;
}

.cover-background {
  background-color: white;
}

.grid-item:nth-child(1) {
  transition: color 0.2s 50ms ease-in-out, opacity 0.6s 3300ms ease-in-out;
}

.grid-item:nth-child(2) {
  transition: color 0.2s 100ms ease-in-out, opacity 0.6s 3250ms ease-in-out;
}

.grid-item:nth-child(3) {
  transition: color 0.2s 150ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(4) {
  transition: color 0.2s 200ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(5) {
  transition: color 0.2s 250ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(6) {
  transition: color 0.2s 300ms ease-in-out, opacity 0.6s 3250ms ease-in-out;
}

.grid-item:nth-child(7) {
  transition: color 0.2s 350ms ease-in-out, opacity 0.6s 3300ms ease-in-out;
}

.grid-item:nth-child(8) {
  transition: color 0.2s 400ms ease-in-out, opacity 0.6s 3250ms ease-in-out;
}

.grid-item:nth-child(9) {
  transition: color 0.2s 450ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(10) {
  transition: color 0.2s 500ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(11) {
  transition: color 0.2s 550ms ease-in-out, opacity 0.6s 3100ms ease-in-out;
}

.grid-item:nth-child(12) {
  transition: color 0.2s 600ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(13) {
  transition: color 0.2s 650ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(14) {
  transition: color 0.2s 700ms ease-in-out, opacity 0.6s 3250ms ease-in-out;
}

.grid-item:nth-child(15) {
  transition: color 0.2s 750ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(16) {
  transition: color 0.2s 800ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(17) {
  transition: color 0.2s 850ms ease-in-out, opacity 0.6s 3100ms ease-in-out;
}

.grid-item:nth-child(18) {
  transition: color 0.2s 900ms ease-in-out, opacity 0.6s 3050ms ease-in-out;
}

.grid-item:nth-child(19) {
  transition: color 0.2s 950ms ease-in-out, opacity 0.6s 3100ms ease-in-out;
}

.grid-item:nth-child(20) {
  transition: color 0.2s 1000ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(21) {
  transition: color 0.2s 1050ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(22) {
  transition: color 0.2s 1100ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(23) {
  transition: color 0.2s 1150ms ease-in-out, opacity 0.6s 3100ms ease-in-out;
}

.grid-item:nth-child(24) {
  transition: color 0.2s 1200ms ease-in-out, opacity 0.6s 3050ms ease-in-out;
}

.grid-item:nth-child(25) {
  transition: color 0.2s 1250ms ease-in-out, opacity 0.6s 3000ms ease-in-out;
}

.grid-item:nth-child(26) {
  transition: color 0.2s 1300ms ease-in-out, opacity 0.6s 3050ms ease-in-out;
}

.grid-item:nth-child(27) {
  transition: color 0.2s 1350ms ease-in-out, opacity 0.6s 3100ms ease-in-out;
}

.grid-item:nth-child(28) {
  transition: color 0.2s 1400ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(29) {
  transition: color 0.2s 1450ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(30) {
  transition: color 0.2s 1500ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(31) {
  transition: color 0.2s 1550ms ease-in-out, opacity 0.6s 3100ms ease-in-out;
}

.grid-item:nth-child(32) {
  transition: color 0.2s 1600ms ease-in-out, opacity 0.6s 3050ms ease-in-out;
}

.grid-item:nth-child(33) {
  transition: color 0.2s 1650ms ease-in-out, opacity 0.6s 3100ms ease-in-out;
}

.grid-item:nth-child(34) {
  transition: color 0.2s 1700ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(35) {
  transition: color 0.2s 1750ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(36) {
  transition: color 0.2s 1800ms ease-in-out, opacity 0.6s 3250ms ease-in-out;
}

.grid-item:nth-child(37) {
  transition: color 0.2s 1850ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(38) {
  transition: color 0.2s 1900ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(39) {
  transition: color 0.2s 1950ms ease-in-out, opacity 0.6s 3100ms ease-in-out;
}

.grid-item:nth-child(40) {
  transition: color 0.2s 2000ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(41) {
  transition: color 0.2s 2050ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(42) {
  transition: color 0.2s 2100ms ease-in-out, opacity 0.6s 3250ms ease-in-out;
}

.grid-item:nth-child(43) {
  transition: color 0.2s 2150ms ease-in-out, opacity 0.6s 3300ms ease-in-out;
}

.grid-item:nth-child(44) {
  transition: color 0.2s 2200ms ease-in-out, opacity 0.6s 3250ms ease-in-out;
}

.grid-item:nth-child(45) {
  transition: color 0.2s 2250ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(46) {
  transition: color 0.2s 2300ms ease-in-out, opacity 0.6s 3150ms ease-in-out;
}

.grid-item:nth-child(47) {
  transition: color 0.2s 2350ms ease-in-out, opacity 0.6s 3200ms ease-in-out;
}

.grid-item:nth-child(48) {
  transition: color 0.2s 2400ms ease-in-out, opacity 0.6s 3250ms ease-in-out;
}

.grid-item:nth-child(49) {
  transition: color 0.2s 2450ms ease-in-out, opacity 0.6s 3300ms ease-in-out;
}

.code-unlocked {
  color: #82bafe;
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes logo-animate {
  0% {
    transform: translateX(min(-40vw, -375px));
  }
  100% {
    transform: translateX(min(40vw, 375px));
  }
}
